@media
  (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
    #main-header #main-logo {
      background-image: url('/img/logo@2x.png');
      background-size: 170px;
    }
}

@media all and (max-width: 1679px) {
  #main-header .orange-bar {
    width: 630px;
  }
}
@media all and (max-width: 1599px) {
  #main-header .orange-bar {
    width: 520px;
  }
}

@media all and (max-width: 1439px) {
  #main-header .orange-bar {
    width: 490px;
  }
  #main-about {
    background-position: 100% 100% !important;
  }
}
@media all and (max-width: 1365px) {
  #main-header .orange-bar {
    width: 480px;
  }
}
@media all and (max-width: 1359px) {
  #main-header .orange-bar {
    width: 430px;
  }
}
@media all and (max-width: 1279px) {
  #main-header .orange-bar {
    width: 410px;
  }
}
// 1260px - 1170px
@media all and (max-width: 1259px) {
  .container {
    width: 1120px;
  }
  #page, #sub-page {
    padding: 80px 0 0;
  }
  footer {
    position: relative;
    //margin: 15px 0 0 0;
  }
  #main-header .nav-menu li {
    padding: 20px 10px;
  }
  #sub-footer .box {
    width: 200px;
  }
  #main-featuring .featuring-area .box {
    width: 234px;
  }
  #sub-footer .box-social div {
    width: 234px;
    display: table;
  }
  #main-featuring .featuring-area {
    width: 90.99%;
  }
  #sub-page .sub-page-gallery .featuring-area .box {
    width: 255px;
  }
  #sub-page .sub-page-gallery .featuring-area .box.last .text-heading {
    padding: 40px 30px;
  }

  #sub-page .sub-page-contact {
    padding: 0 20px;
    box-sizing: border-box;
  }
  #sub-page .sub-page-contact .contact-form input.f-email, #sub-page .sub-page-contact .contact-form input.f-name {
    margin: 0 0 4px;
  }
  #sub-page .sub-page-contact .contact-form input {
    margin: 0 0 4px;
    width: 280px;
  }
  #sub-page .sub-page-contact .contact-form select {
    width: 280px;
  }
  #sub-page .sub-page-contact .contact-form .select2-container {
    width: 280px !important;
  }
  #sub-page .sub-page-contact .contact-form textarea {
    width: 564px;
  }
  #main-get-services .box {
    margin: 0 52px;
  }
  #main-reference .references-1 .icon-arrow-right {
    right: -125px;
  }
  #main-about {
    background-position: 98% 100% !important;

    .about-area {
      padding: 0 60px;
    }
  }
  #sub-page .sub-page-contact .contact-form textarea {
    width: 540px;
  }
  #sub-page .sub-page-contact .contact-form input {
    width: 268px;
  }
  #sub-page .sub-page-contact .contact-form .select2-container {
    width: 268px !important;
  }
}

@media all and (min-width: 1170px) and (max-width: 1259px) {
  .header-info.fr {
    font-size: 0.9em;
    div {
      width: 185px !important;
    }
  }
}

//1170px - 970px
@media all and (max-width: 1169px) {
  .container {
    width: 970px;
  }
  #main-header .orange-bar {
    width: 200px;
  }
  #sub-footer .box {
    width: 185px;
  }
  #main-featuring .featuring-area .box {
    width: 220px;
  }
  .sub-page-gallery .featuring-area {
    display: table;
    margin: 0 auto;
    width: 872px;
  }
  #main-featuring .featuring-area {
    width: 99%;
  }
  #sub-page .sub-page-gallery .featuring-area {
    width: 876px;
  }
  #main-reference .references-2 .container-references-2 {
    width: 320px;
  }
  #main-reference .references-1 .icon-arrow-left {
    left: -85px;
  }
  #main-reference .references-1 .icon-arrow-right {
    right: -85px;
  }
  #main-get-services .box {
    width: 50%;
    margin: 0;
  }
  #sub-page .sub-page-services .icon {
    font-size: 117px;
    top: 112px;
  }
  #sub-page .sub-page-contact .contact-form {
    padding: 0 4px 0 0;
  }
  #sub-page .sub-page-contact .contact-form input {
    width: 100%;
  }
  #sub-page .sub-page-contact .contact-form .f-text {
    width: 100%;
  }
  #sub-page .sub-page-contact .contact-form .select2-container {
    width: 100% !important;
    margin: 4px 0;
  }
  #sub-page .sub-page-contact .contact-form input.f-submit {
    margin: 20px 0;
  }
  #sub-page .sub-page-contact .contact-form .required-text {
    display: table;
  }
  #main-header .header-info {
    margin-right: 15px;
  }
  #main-header .header-info div {
    width: auto;
  }
  #main-header .header-info div span {
    display: none;
  }
  #main-about {

    .about-area {
      padding: 0 15px;
    }
  }
}

// 970px - 620px
@media all and (max-width: 969px) {
  .container {
    width: 620px;
  }
  #main-header .orange-bar {
    width: 150px;
  }
  header {
    position: fixed !important;
    z-index: 20;
  }
  footer {
    position: relative;
    //margin: 15px 0 0 0;
  }
  #sub-footer .box {
    width: auto;
    float: none;

    h4 {
      text-align: center;
    }
  }
  #main-header {
    padding: 18px 10px;
  }
  #main-header .container {
    width: 100%;
  }
  #main-header #main-logo {
    left: 0;
  }
  #main-header .icon-menu-large {
    font-size: 20px;
    padding: 5px 30px 0 10px;
    display: table !important;
    visibility: visible;
    cursor: pointer;
    color: #a6a6a6;
    transition: @transition-color;
  }
  #main-header .menu-active {
    color: #000 !important;
  }
  #main-header .nav-menu {
    display: none;
    position: absolute;
    background: #FFF;
    left: 74px;
    margin: -14px -8px;
    padding: 10px 5px;
  }
  #page, #sub-page {
    padding: 80px 0 0;
  }
  #main-featuring .featuring-area .box {
    width: 270px;
  }
  #main-featuring .featuring-area{
    width: 582px;
  }
  #sub-page .sub-page-gallery .featuring-area {
    width: 584px;
  }
  #main-about .about-area {
    width: auto;
    padding: 0 30px;
  }
  #main-about {
    background: none !important;
  }
  #main-about .about-area .box {
    width: 280px;
  }
  #main-reference .box {
    width: 100%;
  }
  #main-reference {
    background: #666;
  }
  #main-reference .container {
    width: 100%;
  }
  #main-reference .references-1 {
    background: #2A4F73;
  }
  #main-reference .references-2 {
    background: #A6A6A6;
  }
  #main-reference .references-2 .heading-references-2 {
    text-align: center;
  }
  #sub-footer .sub-footer-boxes {
    width: 472px;
  }
  #main-get-services .box .icon {
    font-size: 3.25em;
    padding: 10px 25px 52px 0;
  }
  #main-get-services .box a {
    font-size: @font-size-20;
  }
  #footer {
    font-size: 0.9em;
  }
  #footer .container div.fr small {
    margin-left: 70px;
    margin-right: 0;
  }
  #footer .container div.fr .footer-logo {
    float: right;
  }
  #sub-page .sub-page-text.container {
    width: auto;
    padding: 0 10px;
  }
  #sub-footer .box h4 {
    margin: 40px 0px 20px;
  }
  #sub-footer .box-guaranty .guaranty-logo {
    margin: 0 auto 40px;
  }
  #sub-footer .box {
    margin: 20px 0;
  }
  #sub-footer .box-social div {
    margin: 0 auto;
  }
  #sub-footer .box-links div {
    float: none;
    margin: 0 auto;
  }
  #sub-footer .box p {
    text-align: center;
  }
  #sub-page .sub-page-services .item {
    width: auto;
  }
  #sub-page .sub-page-services .icon {
    display: none;
  }
  #sub-page .sub-page-contact .col {
    width: 100%;
  }
  #sub-page .sub-page-contact .contact-form {
    padding: 0 4px 0 4px;
  }
  #sub-page .item {
    margin: 50px 5px;
  }
}

// 620px - 480px
@media all and (max-width: 619px) {
  body {
    font-size: 0.9em;
  }
  .container {
    width: auto;
  }
  #main-get-services .box {
    width: 100%;
    margin: 0 auto;
    float: none;
    padding: 0 10px;
  }
  #main-featuring .featuring-area {
    width: 482px;
  }
  #main-about .about-area {
    padding: 0 80px;
  }
  #main-about .about-area .box {
    width: 255px;
  }
  #main-about .about-area .box p {
    width: 205px;
  }
  #sub-page .sub-page-gallery .featuring-area {
    width: 486px;
  }
  #main-featuring .featuring-area .box, #sub-page .sub-page-gallery .featuring-area .box {
    width: 220px;
  }
  #footer .container div.fr {
    width: auto;
  }
  #footer .container div.fr small{
    display: none;
  }
  #copyrightspopup {
    white-space: normal !important;
  }
}


@media all and (max-width: 579px) {
  #main-header .nav-menu {
    width: 81%;
  }
  #main-header .nav-menu li {
    padding: 20px 4px;
    font-size: 0.9em;
  }
  #main-header .nav-menu {
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    top: 76px;
    border-bottom: 1px solid #bfbfbf;
  }
  #main-header .nav-menu li {
    padding: 0;
    display: table;
    font-size: 15px;
    line-height: 20px;
    width: 100%;
  }
  #main-header .nav-menu li a {
    padding: 8px 20px;
    width: 100%;
    display: table;
    box-sizing: border-box;
  }
  #main-header .icon-menu-large {
    display: none !important;
    visibility: hidden;
  }
  #main-header .icon-menu-small {
    font-size: 20px;
    padding: 5px 30px 0 10px;
    display: table !important;
    visibility: visible;
    cursor: pointer;
    color: #a6a6a6;
    transition: @transition-color;
  }
  #main-about .about-area .box {
    width: 242px;
  }
  #main-about .about-area .box p {
    width: 195px;
  }
  #main-about .about-area p {
    line-height: 24px;
  }
}

// 480px - 360px
@media all and (max-width: 479px) {
  body {
    font-size: 0.8em;
  }
  section {
    padding: 20px !important;
  }
  #main-header {
    height: 62px;
    overflow: visible;
  }
  #main-header .nav-menu {
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    top: 58px;
    border-bottom: 1px solid #bfbfbf;
  }
  #main-header .nav-menu li {
    padding: 0;
    display: table;
    font-size: 15px;
    line-height: 20px;
    width: 100%;
  }
  #main-header .nav-menu li a {
    padding: 8px 20px;
    width: 100%;
    display: table;
  }
  #main-header .icon-menu-large {
    display: none !important;
    visibility: hidden;
  }
  #main-header .icon-menu-small {
    font-size: 20px;
    padding: 0 30px 0 0;
    display: table !important;
    visibility: visible;
    cursor: pointer;
    color: #a6a6a6;
    transition: @transition-color;
  }
  #main-header .header-info-heading {
    font-size: 15px;
    text-transform: uppercase;
    color: @main-orange;
    font-weight: 600;
    margin: 2px;
    cursor: pointer;
    display: block !important;
    visibility: visible;
  }
  #main-header .header-info-heading:hover, #main-header .header-info-heading:active, .header-info-heading-active {
    text-decoration: underline;
  }
  #main-header .header-info {
    margin-right: 0;
  }
  #main-header .header-info {
    position: absolute;
    width: 100%;
    left: 0;
    top: 58px;
    background: rgb(255, 255, 255);
    padding: 10px;
    font-size: 0.9em;
    box-sizing: border-box;
    display: none;
    border-bottom: 4px solid @main-orange;
  }
  #main-header .header-info div {
    width: 175px;
  }
  #main-header .header-info div span.fl {
    font-size: 15px;
    margin-top: 5px;
    display: table;
  }
  #main-header .header-info div span.fl span {
    display: none;
  }

  #main-header #main-logo {
    height: 23px;
  }
  #main-header .orange-bar {
    display: none;
  }
  #page, #sub-page {
    padding: 62px 0 0;
  }
  #main-reference {
    padding: 0 !important;
  }
  #main-featuring .featuring-area, #sub-page .sub-page-gallery .featuring-area {
    width: 312px;
  }
  #main-featuring .featuring-area .box, #sub-page .sub-page-gallery .featuring-area .box {
    width: 290px;
  }
  #main-reference .references-1 strong {
    font-size: 2.75em;
  }
  #main-reference .references-1 .container-references-1, #main-reference .references-2 .container-references-2 {
    width: 220px;
  }
  #main-reference .references-1 .icon-arrow-right {
    right: -60px;
  }
  #main-reference .references-1 .icon-arrow-left {
    left: -60px;
  }
  #main-reference .box {
    padding: 20px;
  }
  #main-reference .references-2 .owl-pagination{
    margin-top: 45px;
  }
  #sub-page .list strong {
    width: 160px;
  }
  #sub-page .list:nth-child(2) strong {
    width: 100px;
  }
  #sub-footer .sub-footer-boxes {
    width: 320px;
  }
  #sub-footer .box {
    width: 100%;
  }
  #footer {
    font-size: 0.7em;
  }
  #sub-page ul {
    margin: 10px 0;
  }
  .container-references-2 small {
    width: 140px;
    display: table;
  }
  #sub-page .sub-page-contact .contact-form .error-text {
    margin-top: 70px;
  }
  #sub-page .sub-page-contact {
    padding: 0;
  }
  #main-about .about-area {
    padding: 0 60px;
  }
}

@media all and (max-width: 400px) {
  #main-header .header-info div {
    width: 100%;
  }
  #main-header .header-info div span.fl {
    margin-top: 12px;
  }
  #main-header .header-info div .icon {
    float: right;
    padding: 5px 10px;
  }
}

// 360px - 320px
@media all and (max-width: 359px) {
  #main-header #main-logo {
    width: 132px;
    background-size: 100%;
  }
  #main-featuring .featuring-area, #sub-page .sub-page-gallery .featuring-area {
    width: 280px;
  }
  #main-featuring .featuring-area .box, #sub-page .sub-page-gallery .featuring-area .box {
    width: 260px;
  }
  #sub-footer .box {
    width: auto;
  }
  #main-about .about-area {
    padding: 0 30px;
  }
  #main-about .about-area .box, #main-about .about-area .box p {
     width: 230px;
  }
  #main-reference .references-1 .icon-arrow-left {
    left: -40px;
  }
  #main-reference .references-1 .icon-arrow-right {
    right: -40px;
  }
  #footer .container div.fr .footer-logo {
    width: 56px;
    background-size: 100%;
  }
  #sub-page ul li::before {
    top: 6px;
    right: 28px;
    margin-right: -15px;
  }
  #sub-page .list strong {
    width: 135px;
  }

  #sub-page .list:nth-child(2) strong {
    width: 80px;
  }
}
