@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?6c6mdy');
  src: url('../fonts/icomoon.eot?#iefix6c6mdy') format('embedded-opentype'),
  url('../fonts/icomoon.woff?6c6mdy') format('woff'),
  url('../fonts/icomoon.ttf?6c6mdy') format('truetype'),
  url('../fonts/icomoon.svg?6c6mdy#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-featuring-9:before {
  content: "\e900";
}
.icon-featuring-10:before {
  content: "\e901";
}
.icon-featuring-11:before {
  content: "\e902";
}
.icon-featuring-12:before {
  content: "\e903";
}
.icon-featuring-13:before {
  content: "\e904";
}
.icon-featuring-14:before {
  content: "\e905";
}
.icon-menu:before {
  content: "\e611";
}

.icon-twitter:before {
  content: "\f099";
}

.icon-facebook:before {
  content: "\f09a";
}

.icon-youtube:before {
  content: "\f167";
}

.icon-about-1:before {
  content: "\e600";
}

.icon-about-2:before {
  content: "\e601";
}

.icon-alert:before {
  content: "\e602";
}

.icon-arrow-down:before {
  content: "\e603";
}

.icon-arrow-left:before {
  content: "\e604";
}

.icon-arrow-right:before {
  content: "\e605";
}

.icon-carousel:before {
  content: "\e606";
}

.icon-featuring-1:before {
  content: "\e607";
}

.icon-featuring-2:before {
  content: "\e608";
}

.icon-featuring-3:before {
  content: "\e609";
}

.icon-featuring-4:before {
  content: "\e60a";
}

.icon-featuring-5:before {
  content: "\e60b";
}

.icon-featuring-6:before {
  content: "\e60c";
}

.icon-featuring-7:before {
  content: "\e60d";
}

.icon-featuring-8:before {
  content: "\e60e";
}

.icon-order-1:before {
  content: "\e60f";
}

.icon-order-2:before {
  content: "\e610";
}
