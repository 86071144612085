@main-blue: #498ac9;
@main-orange: #FC783F;
@main-white: #fff;
@main-color: #000;
@heading-color: #F3F7FB;
@text-color-1: #444;
@text-color-2: #070c12;
@header-background: #fff;
@sub-footer-color: #132333;
@footer-color: #141414;
@footer-color-2: #b2b2b2;
@footer-color-3: #bfbfbf;
@background: #fff;
@background-featuring: url("../img/bg-blue.jpg");
@footer-logo: url("../img/new-logic-logo.png");
@link-color: #444;

@font-size-initial: 1em;
@font-size-60: 3.75em;
@font-size-35: 2.1875em;
@font-size-25: 1.5625em;
@font-size-20: 1.25em;
@font-size-17: 1.0625em;
@font-size-15: 0.9375em;

@transition-color: color 0.5s ease 0s;
@transition-background: background 0.5s ease 0s;

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

html {
  height: 100%;
}

body {
  background: @background;
  font-family: 'Roboto', sans-serif;
  font-size: @font-size-initial;
  cursor: default;
  color: @main-color;
  min-height: 100%;
  overflow-x: hidden;
  position: relative;
}

a {
  color: @link-color;
  text-decoration: none;
  transition: color 0.5s ease 0s;
}
.sub-page-text a, .text a {
  text-decoration: underline;
}
a:hover {
  color: @main-blue;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.container {
  width: 1260px;
  margin: 0 auto;
  display: table;
}

#page {
  padding: 80px 0 0;
}

p a[href], #sub-page ul li a[href] {
  text-decoration: underline;
}

#main-featuring {
  background: @background-featuring;
  padding: 20px 0;
  .featuring-heading {
    h1 {
      color: @heading-color;
      text-align: center;
      text-transform: uppercase;
      font-weight: 300;
      margin: 15px 0 5px;
    }
    p {
      color: @heading-color;
      text-align: center;
      margin: 0 0 10px;
      font-weight: 300;
    }
    .icon {
      display: table;
      color: #F3F7FB;
      font-size: 8px;
      margin: -5px auto 20px;
    }

  }
  .featuring-area {
    width: 85%;
    margin: 0 auto;
    display: block;
    .box {
      width: 246px;
      height: 190px;
      background: none repeat scroll 0 0 rgb(219, 232, 244);
      text-align: center;
      float: left;
      display: table;
      margin: 10px;
      transition: @transition-background;
    }
    .box:hover {
      background: #1b3249;
    }
    span {
      font-size: @font-size-17;
      font-weight: 100;
    }
    .box .icon {
      font-size: 5.25em;
      display: block;
      padding: 26px;
      color: #1b3249;
      transition: @transition-color;
    }
    .box:hover .icon {
      color: @main-blue;
    }
    .box:hover .text {
      visibility: hidden;
      display: none !important;
    }
    .box:hover .hidden {
      visibility: visible;
      display: inline !important;
      text-transform: uppercase;
      color: @main-orange;
      text-decoration: underline;
      font-weight: 600;
    }
  }
}

#main-about {
  background: @main-white;
  background-position: 80% 100% !important;
  padding: 50px 0;
  .about-area {
    width: 737px;
    display: table;
    padding: 0 105px;
    h2 {
      color: @text-color-1;
      font-size: @font-size-35;
      font-weight: 300;
      margin: 0;
    }
    p {
      color: @text-color-1;
      font-weight: 300;
      margin-bottom: 65px;
      margin-right: 50px;
      line-height: 28px;
    }
    a {
      text-decoration: underline;
    }
    .box {
      float: left;
      width: 365px;
      position: relative;
      left: 45px;
      h3 {
        color: @main-blue;
        font-size: @font-size-15;
        text-transform: uppercase;
        display: inline;
      }
      p {
        color: @text-color-2;
        margin-bottom: 0;
      }
      .icon {
        color: @main-blue;
        font-size: 1.375em;
        vertical-align: text-top;
        padding: 0 10px 0 0;
        font-weight: 600;
        position: absolute;
        left: -45px;
      }
    }
  }
}

#main-reference {
  background: rgb(166, 166, 166); /* Old browsers */
  background: -moz-linear-gradient(left, rgba(42, 79, 115, 1) 50%, rgba(166, 166, 166, 1) 50%); /* FF3.6+ */
  //  background: -webkit-gradient(linear, left top, right top, color-stop(50%,rgba(42,79,115,1)), color-stop(50%,rgba(166,166,166,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, rgba(42, 79, 115, 1) 50%, rgba(166, 166, 166, 1) 50%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, rgba(42, 79, 115, 1) 50%, rgba(166, 166, 166, 1) 50%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left, rgba(42, 79, 115, 1) 50%, rgba(166, 166, 166, 1) 50%); /* IE10+ */
  background: linear-gradient(to right, rgba(42, 79, 115, 1) 50%, rgba(166, 166, 166, 1) 50%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2a4f73', endColorstr='#a6a6a6', GradientType=1); /* IE6-9 */
  min-height: 300px;
  .box {
    width: 50%;
    display: block;
    float: left;
    box-sizing: border-box;
    padding: 20px 80px;
  }
  .references-1 {
    .heading-references-1 {
      font-size: @font-size-35;
      font-weight: 300;
      text-align: center;
      color: #A6A6A6;
    }
    .container-references-1 {
      width: 300px;
      display: block;
      margin: 0 auto;
      position: relative;
    }
    strong {
      font-size: @font-size-25;
      margin: 0 auto;
      display: table;
      text-align: center;
      color: @main-blue;
    }
    p {
      color: #fff;
      text-align: center;
      font-size: @font-size-17;
      font-weight: 300;
    }
    a.icon {
      color: @main-white;
      font-size: 20px;
      position: absolute;
      padding: 20px;
      cursor: pointer;
    }
    a.icon:hover {
      color: @main-blue;
    }
    .icon-arrow-left {
      left: -150px;
    }
    .icon-arrow-right {
      right: -150px;
    }
  }
  .references-2 {
    .heading-references-2 {
      font-size: @font-size-35;
      font-weight: 300;
      text-align: left;
      color: #2A4F73;
    }
    .container-references-2 {
      width: 420px;
      display: block;
      margin: 0 auto;
    }

    p {
      color: #fff;
      font-size: @font-size-17;
      font-style: italic;
      font-weight: 300;
      min-height: 66px;
    }
    small {
      font-size: @font-size-17;
      color: #2A4F73;
      font-weight: 300;
      display: table;
      width: 210px;
    }
    .owl-theme .owl-controls {
      text-align: right;
      display: table;
      float: right;
      position: relative;
      top: -30px;
      z-index: 2;
      margin-top: 0;
    }
    .owl-theme .owl-dots .owl-dot span{
      font-size: 1.75em;
      padding: 0 5px;
      background: transparent;
      color: #86888a;
    }
    .owl-theme .owl-dots .owl-dot span:hover {
      color: #2A4F73;
    }
    .owl-theme .owl-dots .owl-dot.active span {
      color: #2A4F73;
    }
    .owl-theme .owl-dots .owl-dot span:before {
      font-family: 'icomoon';
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e606";
    }
  }
}

#main-get-services {
  background: #fff;
  h2 {
    color: @text-color-1;
    font-size: @font-size-35;
    font-weight: 300;
    text-align: center;
  }
  .container {
    padding: 0 0 40px;
  }
  .box {
    width: 40%;
    display: table;
    float: left;
    box-sizing: border-box;
    padding: 20px 10px;
    margin: 0 62px;
    p {
      font-size: @font-size-17;
    }
    a {
      font-size: @font-size-25;
      color: @main-blue;
      font-weight: 600;
      text-transform: uppercase;
    }
    a:hover {
      text-decoration: underline;
    }
    .icon {
      display: table;
      font-size: 5.25em;
      padding: 5px 45px 0 0;
      color: @main-blue;
    }
  }
}

#sub-page {
  padding: 80px 0 0;
  .item {
    margin: 50px 0;
  }
  h1 {
    font-size: @font-size-35;
    font-weight: 300;
  }
  h3 {
    font-size: @font-size-15;
    font-weight: 600;
    text-transform: uppercase;
    color: @main-blue;
  }
  p {
    font-size: @font-size-17;
    font-weight: 300;
  }
  ul {
    font-size: @font-size-15;
    line-height: @font-size-25;
    font-weight: 300;
    padding: 0 20px;
    margin: 10px 40px 10px 0;
    width: 280px;
    text-indent: -18px;
    position: relative;
    left: 18px;
    li {
      list-style: none;
    }
    li:before {
      content: "• ";
      color: @main-blue;
      font-size: @font-size-35;
      vert-align: middle;
      position: relative;
      top: 8px;
      right: 15px;
    }
  }
  .sub-page-text.container {
    width: 735px;
  }
  .sub-page-services {
    position: relative;
    .icon {
      position: absolute;
      font-size: 216px;
      right: 20px;
      top: 50px;
      color: #E6E6E6;
    }
  }
  .sub-page-services .item {
    width: 735px;
    padding: 0 10px;
  }
  .sub-page-gallery {
    padding: 0px 5px;
    box-sizing: border-box;
    .featuring-heading {
      h1 {
        color: #000000;
        font-size: @font-size-35;
        text-align: left;
        font-weight: 100;
        margin: 45px 10px;
      }
      p {
        color: @heading-color;
        text-align: left;
        margin: 0 0 10px;
        font-weight: 300;
        padding: 0 10px;
        box-sizing: border-box;
      }
    }
    .featuring-area {
      margin: 45px auto;
      display: table;
      .box {
        width: 290px;
        height: 190px;
        background: #fff;
        text-align: center;
        float: left;
        display: table;
        margin: 10px;
        border: 1px solid #86888a;
        transition: @transition-background;
        span {
          font-size: @font-size-17;
          font-weight: 100;
        }
      }
      .box .icon {
        font-size: 6.25em;
        display: block;
        padding: 26px;
        color: #1b3249;
        transition: @transition-color;
      }
      .box:hover {
        background: #1b3249;
      }
      .box:hover .text {
        visibility: hidden;
        display: none !important;
      }
      .box:hover .hidden {
        visibility: visible;
        display: inline !important;
        text-transform: uppercase;
        color: @main-orange;
        text-decoration: underline;
        font-weight: 600;
      }
      .box:hover .icon {
        color: @main-blue;
      }
      .box.last {
        background: #1b3249;
        color: @main-blue;
      }
      .box.last .text-heading {
        color: @main-white;
        display: table;
        margin: 0 auto;
        font-size: 1.875em;
        padding: 56px 30px;
      }
      .box.last .text {
        text-transform: uppercase;
        text-decoration: underline;
        font-weight: 600;
      }
      .box.last:hover .text {
        visibility: visible;
        display: inline !important;
        color: @main-orange;
      }
    }
  }
  .sub-page-contact {
    .col {
      width: 50%;
    }
    h2 {
      font-size: @font-size-35;
      font-weight: 100;
    }
    .contact-form {
      position: relative;
      input {
        padding: 15px;
        margin: 0 0 27px;
        width: 290px;
        box-sizing: border-box;
        border: 1px solid #86888a;
      }
      input:focus {
        background: @main-blue;
        color: #fff;
        border: 1px solid #86888a;
      }
      input.f-name {
        margin: 0 27px 27px 0;
      }
      input.f-email {
        margin: 0 27px 27px 0;
      }
      select {
        padding: 15px;
        width: 290px;
        box-sizing: border-box;
        border: 1px solid #86888a;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
      select .option {
        background: #fff;
        color: #a6a8a9;
        font-weight: 300;
      }
      select .option:last-child {
      }

      .blue {
        background: @main-blue;
        color: #fff;
        border: 1px solid #86888a;
      }
      .select2-selection {
        border-radius: 0;
        height: 52px;
        margin-top: -4px;
        border: 1px solid #86888a;
      }
      .select2-selection__rendered {
        line-height: 48px;
      }
      .select2-selection__arrow b {
        visibility: hidden;
      }
      .select2-selection__arrow:before {
        font-family: 'icomoon';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e603";
        font-size: 0.5em;
        position: relative;
        top: 8px;
        right: 8px;
      }
      textarea {
        width: 610px;
        padding: 15px;
        height: 150px;
        box-sizing: border-box;
      }
      textarea:focus {
        background: @main-blue;
        color: #fff;
        border: 1px solid #86888a;
      }
      input.f-submit {
        background: none;
        border: none;
        padding: 0;
        float: right;
        color: @main-blue;
        text-transform: uppercase;
        font-size: @font-size-20;
        font-weight: 600;
        display: table;
        margin: 20px;
        width: auto;
        cursor: pointer;
      }
      input.f-submit:hover {
        text-decoration: underline;
      }
      .error-text {
        display: table;
        margin: 20px 0;
        color: @main-orange;
        .icon {
          padding: 20px 20px 20px 0;
          font-size: @font-size-25;
          vertical-align: sub;
        }
      }
      .error {
        border: 1px solid @main-orange;
      }
      div.good {
        color: #008000;
        padding: 0 0 10px;
      }
      div.error {
        color: @main-orange;
      }
      .errorContactForm {
        color: #FC783F;
        padding: 0 0 10px;
      }
      .required-text {
        position: absolute;
        color: #FC783F;
        margin: 2px 0 0 5px;
        z-index: 1;
      }
    }
  }
  .sub-page-contact-map {
    height: 375px;
    width: 100%;
    position: relative;
    overflow: hidden;
    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .list {
    .item;
    padding: 0 10px;
    strong {
      width: 210px;
      font-weight: @font-size-15;
      display: table;
      color: @main-blue;
      text-transform: uppercase;
      padding: 5px 0;
    }
    span {
      padding: 5px 0;
      display: table;
    }
    a {
      padding: 5px 0;
      display: table;
    }
  }
}
.select2-dropdown {
  border-radius: 0 !important;
}

body.sub-page-services #main-get-services {
  background: #e6e6e6;
}
#sub-page .sub-page-gallery .featuring-heading p{
    color: #000000;
}
