footer {
  width: 100%;
  position: static;
  bottom: 0;
}
#sub-footer {
  background: @sub-footer-color;
  min-height: 275px;
  .sub-footer-boxes {
    display: table;
    margin: 0 auto;
  }
  .box {
    font-size: 0.9em;
    float: left;
    width: 315px;
    margin: 20px;
    h4 {
      text-transform: uppercase;
      color: #fff;
      font-weight: 300;
      font-size: @font-size-15;
      letter-spacing: 0.1em;
      margin: 20px 0 40px 0;
    }
    p {
      color: @footer-color-3;
      text-transform: uppercase;
      line-height: 26px;
      font-weight: 300;
    }
  }
  .box-address {
    a {
      color: @footer-color-3;
    }
    a:hover {
      color: @main-blue;
    }
  }
  .box-guaranty {
    width: 210px;
    .guaranty-logo {
      height: 115px;
      display: table;
      width: 116px;
      background: url("../img/guaranty.png") no-repeat;
      margin: 0 20px;
    }
  }
  .box-social {
    width: 235px;
    a {
      font-size: 22px;
      padding: 15px;
      background: #1B3249;
      border-radius: 26px;
      width: 22px;
      display: table;
      text-align: center;
      color: #FFF;
      float: left;
      margin: 0 15px;
      transition: background 0.5s ease 0s;
    }
    a.icon-twitter {
      margin: 0 15px 0 0;
    }
    a.icon-youtube {
      padding: 15px 12px 15px 18px;
    }
    a.icon-youtube:hover {
      background: #e70031;
    }
    a.icon-facebook:hover {
      background: #4E69A2;
    }
    a.icon-twitter:hover {
      background: #55ACEE;
    }
  }
  .box-links {
    div {
      width: 139px;
      margin: 0 15px 0 0;
      display: table;
      a {
        font-size: @font-size-17;
        color: @footer-color-3;
        font-weight: 300;
        margin: 5px 0;
        display: table;
      }
      a:hover {
        position: relative;
        left: 10px;
        color: @main-blue;
      }
    }
  }
}
#footer {
  background: @footer-color;
  min-height: 60px;
  padding: 20px 0;
  box-sizing: border-box;
  .container {
    color: @footer-color-2;
    font-size: @font-size-17;
    font-weight: 300;
    padding: 0 20px;
    box-sizing: border-box;
    small {
      font-size: 100%;
    }
    a {
      color: @footer-color-2;
      font-weight: 300;
      padding: 0 25px;
      text-decoration: underline;
    }
    div.fr {
      width: 260px;
      position: relative;
      small {
        margin-right: 50px;
      }
      .footer-logo {
        background: no-repeat @footer-logo;
        width: 89px;
        height: 23px;
        display: table;
        padding: 0;
        margin: 2px 0;
        cursor: pointer;
      }
      .footer-logo:hover
      {
        opacity: 0.8;
      }
    }
    #copyrightspopup {
      background: none repeat scroll 0 0 #FFF;
      border: 1px solid #DDD;
      border-radius: 5px;
      line-height: 1;
      color: #000;
      font-size: 15px;
      display: none;
      padding: 12px;
      bottom: 60px;
      position: absolute;
      right: 0;
      text-align: center;
      white-space: nowrap;
      z-index: 10000;
    }
    #copyrightspopup a {
      padding: 0;
      color: #575757;
    }
  }
}

