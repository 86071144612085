#main-header {
  width: 100%;
  height: 80px;
  border-top: 4px solid @main-blue;
  background: @header-background;
  padding: 18px 0;
  box-sizing: border-box;
  border-bottom: 1px solid @footer-color-3;
  position: absolute;
  top: 0;
  .orange-bar {
    position: absolute;
    right: 0;
    background: @main-orange;
    width: 748px;
    height: 4px;
    top: -4px;
  }
  #main-logo {
    background: url("../img/logo.png") no-repeat;
    display: block;
    width: 170px;
    height: 42px;
    position: relative;
    left: 15px;
  }
  .nav-menu {
    margin-left: 25px;
    nav {
      overflow: hidden;
      max-width: 600px;
      text-overflow: ellipsis;
    }
    ul {
      list-style: outside none none;
      margin: 0;
      padding: 0;
    }
    li {
      display: inline;
      padding: 20px 10px;
      text-transform: uppercase;
      line-height: 38px;
      font-size: @font-size-initial;
      white-space: nowrap;
    }
  }
  .header-info {
    div {
      width: 205px;
      display: table;
      text-align: right;
      float: left;
      font-weight: 100;
      margin: 0 5px;
      a {
        display: block;
        color: @main-orange;
        font-weight: 600;
      }
      a:hover {
        text-decoration: underline;
      }
      a.icon:hover {
        text-decoration: none;
      }
      .icon {
        display: table;
        font-size: 2.5em;
        padding: 0 10px;
        color: @main-orange;
        font-weight: 300;
      }
    }
  }
}
